<template>
    <section class="cotizaciones-enviadas container-fluid overflow-auto custom-scroll my-2">
        <titulo-divisor titulo="Cotizaciones">
            <div class="row">
                <div class="col auto d-none">
                    <el-input v-model="buscarListado" placeholder="Buscar proveedores" size="small" />
                </div>
                <div v-if="cantFiltros == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="cantFiltros > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">3</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <tabla-general :usarServidor="true" :usarPaginacion="true" :servidorData="cotizacionesEnviadas">
            <el-table-column label="Cotización" prop="cotizacion" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 tres-puntos cr-pointer" @click="verCotizacion(scope.row.id)">
                        {{ scope.row.referencia }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de solicitud" prop="fechaSolicitud" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.fecha_solicitud | helper-fecha('DD MMM YYYY') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Materiales a cotizar" prop="cantMateriales" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.materiales_cotizar }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Materiales cotizados" prop="cantMaterialesCotizados" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.materiales_cotizados }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de cotización" prop="fechaCotizacion" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.fecha_cotizacion | helper-fecha('DD MMM YYYY') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor de cotización" prop="valor" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor_cotizacion) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Días de crédito" prop="diasCredito" align="center" width="75">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.dias_credito }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Días de vigencia" prop="diasVigencia" align="center" width="75">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.dias_vigencia }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 mb-1">
                    <p class="input-label-top">Rango fecha de solicitud</p>
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Desde</p>
                    <el-date-picker v-model="pickerFecha" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Hasta</p>
                    <el-date-picker v-model="pickerFecha" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-12 mb-1">
                    <p class="input-label-top">Rango fecha de cotización</p>
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Desde</p>
                    <el-date-picker v-model="pickerFecha" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Hasta</p>
                    <el-date-picker v-model="pickerFecha" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-12 my-3">
                    <el-checkbox-group v-model="checkEstados" class="mt-1" fill="#0B56A7">
                        <el-checkbox label="Materiales no cotizados" class="d-flex mb-2" />
                        <el-checkbox label="Materiales cotizados" class="d-flex mb-2" />
                    </el-checkbox-group>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return{
            buscarListado:'',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            cantFiltros: 0,
            valueSelect: '',
            estadoFilter: '',
            checkEstados: [],
            listadoCotizacionesVigentes:[
                {
                    id: 1,
                    cotizacion: '0001',
                    fechaSolicitud: '15 Ene. 2020',
                    cantMateriales: 80,
                    cantMaterialesCotizados: 80,
                    fechaCotizacion: '20 Abr. 2021',
                    valor: '$ 85.000,00',
                    diasCredito: 20,
                    diasVigencia: 15,
                },],
            pickerFecha: '',
        }
    },
    computed:{
        ...mapGetters({
            cotizacionesEnviadas: 'cotizacion/cotizacionesVigentes/getListCotizacionesEnviadas'
        }),
    },
    async created(){
      await this.Action_get_cotizaciones_enviadas()
    },
    methods:{
        ...mapActions({
            Action_get_cotizaciones_enviadas: 'cotizacion/cotizacionesVigentes/Action_get_cotizaciones_enviadas'
        }),
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        filtrarMateriales(){
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 1
        },
        limpiarFiltro(){
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 0
        },
        verCotizacion(id_cotizacion){
            this.$router.push({
                name: 'cotizaciones.ver.enviadas',
                params: {
                    id_cotizacion: id_cotizacion
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.cotizaciones-enviadas{
    height: calc(100vh - 245px);
}
</style>